import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../../../domain/controllers/ControllerContext';
import {EstimatedTax} from './EstimatedTax/EstimatedTax';
import {OrderSummaryDataHook} from '../OrderSummary';
import s from '../OrderSummary.scss';
import {TotalShipping} from './TotalShipping/TotalShipping';
import {DeliveryMethod} from './DeliveryMethod/DeliveryMethod';
import {DeliveryError} from './DeliveryError/DeliveryError';
import {DeliveryNotification} from './DeliveryNotification/DeliveryNotification';
import {AdditionalFees} from './AdditionalFees/AdditionalFees';

const FormattedSubTotal = () => {
  const {t} = useTranslation();
  const {estimatedTotals} = useControllerProps().cartStore;

  return (
    <dl className={s.line}>
      <dt>{t('cart.subtotal')}</dt>
      <dd
        data-hook={OrderSummaryDataHook.SubTotalsValue}
        data-wix-sub-totals-value={OrderSummaryDataHook.SubTotalsValue}>
        {estimatedTotals.priceSummary.subtotal.formattedConvertedAmount}
      </dd>
    </dl>
  );
};

export const SubTotals = () => {
  const {
    shouldDisplayDelivery,
    shouldDisplayDeliveryMethodSelection,
    shouldDisplayTax,
    shouldDisplayDeliveryError,
    isFullAddressRequired,
    shouldDisplayAdditionalFees,
  } = useControllerProps().orderStore;

  return (
    <div data-hook={OrderSummaryDataHook.SubTotalsRoot} className={s.divider}>
      <FormattedSubTotal />

      {shouldDisplayAdditionalFees && <AdditionalFees />}

      {shouldDisplayDelivery && <TotalShipping />}

      {shouldDisplayDeliveryMethodSelection && <DeliveryMethod />}

      {shouldDisplayTax && <EstimatedTax />}

      {shouldDisplayDeliveryError && (isFullAddressRequired ? <DeliveryNotification /> : <DeliveryError />)}
    </div>
  );
};
