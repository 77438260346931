export function promisify<T extends (...args: any[]) => ReturnType<T>>(fn: T) {
  return (...args: Parameters<T>): Promise<ReturnType<T>> =>
    new Promise((resolve, reject) => {
      try {
        resolve(fn(...args));
      } catch (e) {
        reject(e);
      }
    });
}
