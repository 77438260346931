import React from 'react';
import {useSlotPlaceholder} from '@wix/widget-plugins-ooi';

export const SlotPlaceHolderDataHook = (slotId: string) => `data-slot-${slotId}`;

export const SlotPlaceHolder = ({slotId}) => {
  const [BottomSlotPlaceholder] = useSlotPlaceholder(slotId);

  return (
    <div data-hook={SlotPlaceHolderDataHook(slotId)}>
      <BottomSlotPlaceholder />
    </div>
  );
};
