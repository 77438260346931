import React from 'react';

export const TagIcon = () => {
  return (
    <svg viewBox="0 0 14 16" fill="currentColor" width="14" height="16">
      <g id="final-cart" stroke="none" fill="none" strokeWidth="1" fillRule="evenodd">
        <g id="general-layout" transform="translate(-515 -839)" fill="currentColor">
          <g id="coupon-icon" transform="rotate(30 -1300.653 1393.349)">
            <path
              d="M1,14.0046024 C0.999339408,13.9996515 9.00460243,14 9.00460243,14 C8.99965149,14.0006606 9,5.41421356 9,5.41421356 L5,1.41421356 L1,5.41421356 L1,14.0046024 Z M-2.72848411e-12,5 L5,-4.66116035e-12 L10,5 L10,14.0046024 C10,14.5543453 9.5443356,15 9.00460243,15 L0.995397568,15 C0.445654671,15 -2.72848411e-12,14.5443356 -2.72848411e-12,14.0046024 L-2.72848411e-12,5 Z"
              id="Rectangle-6"
              fillRule="nonzero"
            />
            <circle id="Oval-2" cx="5" cy="5" r="1" />
          </g>
        </g>
      </g>
    </svg>
  );
};
