import React, {useRef} from 'react';
import {BuyerNote} from '../../Components/BuyerNote/BuyerNote';
import {CartItems} from '../../../../../common/components/CartItems/CartItems';
import {CouponOld} from '../../Components/Coupon/CouponOld/CouponOld';
import {Headline} from '../../Components/Headline/Headline';
import {OrderSummary} from '../../Components/OrderSummary/OrderSummary';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import s from './CartBody.scss';
import {CheckoutButtons} from '../../Components/CheckoutButtons/CheckoutButtons';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {CartScreenReaderMessage} from './CartScreenReaderMessage/CartScreenReaderMessage';
import {useIsAfterHydration} from '../../../../../hooks/useIsAfterHydration';
import {Coupon} from '../../Components/Coupon/Coupon';

export enum CartBodyDataHook {
  CartAsideLoader = 'CartBody.aside',
  CartAsideContent = 'CartBody.content',
}

export const CartBody = () => {
  const {shouldShowCoupon, shouldShowBuyerNote, shouldShowTopCheckoutButtonInMobile, refactorCartCouponSection} =
    useControllerProps().cartStore;
  const {isMobile, isEditorX} = useEnvironment();
  const cartBodyRef = useRef<HTMLDivElement>(null);

  const isAfterHydration = useIsAfterHydration();

  let mainClassName = s.main;

  /* istanbul ignore next */
  if (cartBodyRef.current?.clientWidth <= 920) {
    mainClassName = 'cartBodyMainOneColumn';
  }

  return (
    <main className={mainClassName} ref={cartBodyRef}>
      <section className={s.cart}>
        {isMobile && shouldShowTopCheckoutButtonInMobile && !isEditorX && isAfterHydration && (
          <CheckoutButtons isOnTop />
        )}
        <Headline />
        <CartItems showQuantityBySettings showThumbnailBySettings shouldShowItemInfoBySettings />
        <footer className={s.footer}>
          {shouldShowCoupon && <div className={s.coupon}>{refactorCartCouponSection ? <Coupon /> : <CouponOld />}</div>}
          {shouldShowBuyerNote && (
            <div className={s.buyerNote}>
              <BuyerNote />
            </div>
          )}
        </footer>
        <CartScreenReaderMessage />
      </section>
      <aside className={s.summary}>
        <OrderSummary />
      </aside>
    </main>
  );
};
