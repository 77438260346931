import React from 'react';

export const DocumentIcon = (props: {className?: string}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" {...props}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-515 -882)">
          <g transform="translate(515 882)">
            <path stroke="currentColor" d="M.5.5h7.778L11.5 3.737V12.5H.5V.5z"></path>
            <path stroke="currentColor" d="M10.793 3.5H8.5V1.207L10.793 3.5z"></path>
            <path fill="currentColor" d="M3 3H6V4H3z"></path>
            <path fill="currentColor" d="M3 6H9V7H3z"></path>
            <path fill="currentColor" d="M3 9H9V10H3z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
