import {CouponError} from '../../../../../domain/services/CartService';

export function getTooltipErrorMessageOptions(couponError: CouponError): {subtotal?: string; quantity?: string} {
  switch (couponError.code) {
    case 'ERROR_INVALID_SUBTOTAL':
      return {subtotal: couponError.message};
    case 'ERROR_INVALID_PRODUCT_QUANTITY':
      return {quantity: couponError.message};
    default:
      return {};
  }
}
