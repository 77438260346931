import React from 'react';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {SectionNotification} from 'wix-ui-tpa';
import Error from 'wix-ui-icons-common/on-stage/Error';
import {useTranslation, TFunction} from '@wix/yoshi-flow-editor';
import {useControllerProps} from '../../../../../../../domain/controllers/ControllerContext';
import s from './DeliveryError.scss';
import {CartModel} from '../../../../../../../domain/models/Cart.model';

const getErrorMessage = (t: TFunction, cartModel: CartModel, isZipCodeValid: boolean, isNonShippableCart: boolean) => {
  if (!isZipCodeValid) {
    return t('cart.errors.invalid_zip_postal_code');
  }

  /* istanbul ignore next */
  if (cartModel.contactInfo) {
    const destination =
      cartModel.contactInfo.address.subdivisionFullname || cartModel.contactInfo.address.countryFullname;
    const message = isNonShippableCart ? 'cart.errors.cant_sell_to_country' : 'cart.errors.cant_ship_to_country';
    return t(message, {country: destination});
  }

  /* istanbul ignore next */
  return isNonShippableCart ? t('cart.errors.cant_sell_to_your_country') : t('cart.errors.cant_ship_to_your_country');
};

export const DeliveryError = () => {
  const {t} = useTranslation();
  const {isZipCodeValid} = useControllerProps().orderStore;
  const {isNonShippableCart, cartModel} = useControllerProps().cartStore;

  return (
    <div data-hook={OrderSummaryDataHook.DeliveryErrorRoot}>
      <dl>
        <dt>
          <SectionNotification type="error" data-hook={OrderSummaryDataHook.DeliveryErrorMessage} className={s.root}>
            <SectionNotification.Icon icon={<Error />} />
            <SectionNotification.Text>
              {getErrorMessage(t, cartModel, isZipCodeValid, isNonShippableCart)}
            </SectionNotification.Text>
          </SectionNotification>
        </dt>
      </dl>
    </div>
  );
};
