import React, {useEffect} from 'react';
import settingsParams from '../../../settingsParams';
import {ContinueShopping, ContinueShoppingPosition} from '../ContinueShopping/ContinueShopping';
import {Headline} from '../Headline/Headline';
import {HTMLTag} from '../HTMLTag/HTMLTag';
import s from './EmptyState.scss';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';

export enum EmptyStateDataHook {
  root = 'EmptyState.root',
  title = 'EmptyState.title',
}

export const EmptyState = () => {
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const {pageLoaded, lineItemsLoaded} = useControllerProps().cartStore;

  useEffect(
    () => {
      lineItemsLoaded();
      pageLoaded();
    },
    /* eslint-disable react-hooks/exhaustive-deps*/ []
  );

  return (
    <>
      <Headline />
      <div className={s.emptyState} data-hook={EmptyStateDataHook.root}>
        <HTMLTag data-hook={EmptyStateDataHook.title} type="h3" className={s.title}>
          {getSettingsWithDefaults(settingsParams.CART_EMPTY_CART)}
        </HTMLTag>
        <ContinueShopping position={ContinueShoppingPosition.emptyState} />
      </div>
    </>
  );
};
