import React, {useEffect, useState} from 'react';
import {CheckoutButton} from '../../../../../common/components/CheckoutButton/CheckoutButton';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import s from './CheckoutButtons.scss';
import settingsParams from '../../../settingsParams';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';
import {ExpressButtons} from '../../../../../common/components/ExpressButtons';

export enum CheckoutButtonsDataHook {
  Default = 'CheckoutButtons.default',
  Top = 'CheckoutButtons.topView',
  Wrapper = 'CheckoutButtons.Wrapper',
}

interface CheckoutButtonsProps {
  isOnTop?: boolean;
}

export function CheckoutButtons(props: CheckoutButtonsProps) {
  const {shouldShowExpressCheckout} = useControllerProps().cashierExpressStore;
  const {isFreeCart, isSummaryUpdating} = useControllerProps().cartStore;
  const {isCheckoutRegularFlowEnabled} = useControllerProps().paymentMethodStore;
  const {isCheckoutButtonDisabled, isNavigationToCheckoutInProcess} = useControllerProps().navigationStore;
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const [isLoaded, setIsLoaded] = useState(false);
  const {isOnTop} = props;
  const dataHook = isOnTop ? CheckoutButtonsDataHook.Top : CheckoutButtonsDataHook.Default;

  const showCheckoutButton = isCheckoutRegularFlowEnabled || isFreeCart;

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const renderExpressCheckout = () => {
    if (isLoaded && shouldShowExpressCheckout) {
      return (
        <div className={s.expressButtonsContainer}>
          <ExpressButtons dataHook={CheckoutButtonsDataHook.Wrapper} />
        </div>
      );
    }
    return null;
  };

  return (
    <div data-hook={dataHook} className={s.checkoutButtons}>
      {showCheckoutButton && (
        <CheckoutButton
          disabled={isCheckoutButtonDisabled || isSummaryUpdating}
          text={getSettingsWithDefaults(settingsParams.CART_BUTTON_TEXT)}
          isNavigationToCheckoutInProcess={isNavigationToCheckoutInProcess}
        />
      )}
      {renderExpressCheckout()}
    </div>
  );
}
