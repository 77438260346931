import * as React from 'react';

export interface RightCaretProps extends React.SVGAttributes<SVGElement> {
  size?: string | number;
}

export const Caret: React.FC<RightCaretProps> = ({size, ...props}) => (
  <svg fill="currentColor" width={size || '4'} height={size || '8'} {...props}>
    <path d="M4 4L.506 8 0 7.562 3.11 4 0 .438.506 0z" fillRule="evenodd" />
  </svg>
);

Caret.displayName = 'Caret';
