import {createStylesParams, StyleParamType, wixColorParam, wixFontParam} from '@wix/tpa-settings';
import {CheckoutButtonsPosition} from '@wix/wixstores-client-core';
import {StyleParam} from '../../common/constants';

type IStylesParams = {
  cart_background: StyleParamType.Color;
  cart_dividersColor: StyleParamType.Color;
  cart_textColor: StyleParamType.Color;
  cart_textFontStyle: StyleParamType.Font;
  cart_titlesColor: StyleParamType.Color;
  cart_titlesFontStyle: StyleParamType.Font;
  cart_linksColor: StyleParamType.Color;
  cart_discountNameColor: StyleParamType.Color;
  cartButton_borderColor: StyleParamType.Color;
  cartButton_backgroundColor: StyleParamType.Color;
  cartButton_textColor: StyleParamType.Color;
  cartButton_textFont: StyleParamType.Font;

  [StyleParam.CheckoutButtonsPosition]: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  cart_background: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  cart_titlesColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  cart_titlesFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  cart_textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  cart_textFontStyle: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  cart_dividersColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  cart_linksColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  cart_discountNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  cartButton_borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  cartButton_backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  cartButton_textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  cartButton_textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  [StyleParam.CheckoutButtonsPosition]: {
    type: StyleParamType.Number,
    getDefaultValue: () => Number(CheckoutButtonsPosition.ABOVE_AND_BELOW_CART),
  },
});
