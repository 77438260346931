import React from 'react';
import {classes} from './CartSummarySkeleton.st.css';
import {Skeleton} from '../../../../../../common/components/Skeleton/Skeleton';
import {SkeletonSection} from '../../../../../../common/components/Skeleton/SkeletonSection/SkeletonSection';
import {SkeletonRow} from '../../../../../../common/components/Skeleton/SkeletonRow/SkeletonRow';
import {SkeletonLineWrapper} from '../../../../../../common/components/Skeleton/SkeletonLine/SkeletonLineWrapper';
import {CartBodyDataHook} from '../../../CartApp/partials/CartBody';

export const CartSummarySkeleton = () => (
  <Skeleton className={classes.asideSkeleton} dataHook={CartBodyDataHook.CartAsideLoader}>
    <SkeletonSection>
      <SkeletonRow>
        <SkeletonLineWrapper size="medium" />
        <SkeletonLineWrapper size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLineWrapper size="large" />
        <SkeletonLineWrapper size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLineWrapper size="medium" />
        <SkeletonLineWrapper size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLineWrapper size="large" />
        <SkeletonLineWrapper size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLineWrapper size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLineWrapper size="medium" />
        <SkeletonLineWrapper size="small" />
      </SkeletonRow>
    </SkeletonSection>
    <div className={classes.divider} />
    <SkeletonSection>
      <SkeletonRow>
        <SkeletonLineWrapper size="medium" />
        <SkeletonLineWrapper size="small" />
      </SkeletonRow>
      <SkeletonRow>
        <SkeletonLineWrapper size="xl" />
      </SkeletonRow>
    </SkeletonSection>
  </Skeleton>
);
