import React from 'react';
import {ProductType} from '@wix/wixstores-client-core';
import {TextButton, Tooltip} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {TagIcon} from '../Icons/Tag';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';
import settingsParams from '../../../settingsParams';

export enum CouponDataHook {
  CouponRoot = 'Coupon.Root',
  EnterACouponButton = 'Coupon.EnterACouponButton',
  enterACouponButtonDisabledTooltip = 'Coupon.enterACodeButtonDisabledTooltip',
}

export const Coupon = () => {
  const {t} = useTranslation();
  const {cartModel, isGiftCardMixedCartEnabled} = useControllerProps().cartStore;
  const shouldDisableCoupon = isGiftCardMixedCartEnabled && cartModel.lineItemsTypes.includes(ProductType.GIFT_CARD);
  const {getSettingsWithDefaults} = useSettingsWithDefaults();

  const textButton = (
    <TextButton data-hook={CouponDataHook.EnterACouponButton} prefixIcon={<TagIcon />} disabled={shouldDisableCoupon}>
      {getSettingsWithDefaults(settingsParams.CART_ADD_PROMO_CODE_LINK)}
    </TextButton>
  );

  return (
    <div data-hook={CouponDataHook.CouponRoot}>
      {shouldDisableCoupon ? (
        <Tooltip
          data-hook={CouponDataHook.enterACouponButtonDisabledTooltip}
          content={t('cart.errors.coupons.ERROR_CANT_PURCHASE_GC_MESSAGE')}
          minWidth={220}>
          {textButton}
        </Tooltip>
      ) : (
        textButton
      )}
    </div>
  );
};
